<template>
  <div>
    <edit-component
      :name="this.name"
      :loading="false"
      :status="2"
    >
      <template #inputs>
        <b-form
          ref="form"
          @submit.prevent
        >
          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('Global.name')"
                label-for="mc-name"
                type="text"
              >
                <b-form-input
                  id="mc-name"
                  v-model="form.name"
                  type="text"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                :label="$t('Global.arabic_name')"
                label-for="mc-name_local"
              >
                <b-form-input
                  id="mc-name_local"
                  v-model="form.name_local"
                  type="text"
                />
              </b-form-group>
            </b-col>
             <b-col md="6">
              <b-form-group
                :label="$t('Global.short_name')"
                label-for="mc-short_name"
                type="text"
              >
                <b-form-input
                  id="mc-name"
                  v-model="form.short_name"
                  type="text"
                />
              </b-form-group>
            </b-col>
             <b-col md="6">
              <b-form-group
                :label="$t('Global.code')"
                label-for="mc-code"
                type="text"
              >
                <b-form-input
                  id="mc-code"
                  v-model="form.code"
                  type="text"
                />
              </b-form-group>
            </b-col>
            <!-- <b-col md="6">
              <b-form-group
                :label="$t('Global.description')"
                label-for="mc-description"
                type="text"
              >
                <b-form-input
                  id="mc-description"
                  v-model="form.description"
                  type="text"
                />
              </b-form-group>
            </b-col> -->

            <!-- <b-col md="6">
              <b-form-group
                :label="$t('Global.description_local')"
                label-for="mc-description_local"
              >
                <b-form-input
                  id="mc-description_local"
                  v-model="form.description_local"
                  type="text"
                />
              </b-form-group>
            </b-col> -->
            <!-- submit and reset -->
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click="submit"
              >
                {{$t('Global.submit')}}
              </b-button>
              <b-button @click="cancel"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >
             {{$t('Global.cancel')}}

              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </template>
    </edit-component>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import EditComponent from '@/views/components/table/Edit'

export default {
  name: 'Edit',
  components: {
    EditComponent,
    BCard,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    EditComponent,
    vSelect,
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapGetters({
      item: 'markCategories/item',
      load: 'markCategories/load',
    }),
    id() {
      return this.$route.params.id ? this.$route.params.id : null
    },
    name() {
      // return this.id ? `${this.$t('Global.edit_offering')}` : this.$t('Global.add_offering')
      return this.id
        ? this.$t('Global.edit_markCategory')
        : this.$t('Global.add_markCategory')
    },
  },
  watch: {
  },
  data() {
    return {
      form: {
      },
      lookups: [],
      lookupModules: {
        bylaws: true,
        terms: true,
        faculties: true,
        programs: true,
        programs_details: true,
      },
      form_submit: {},
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions({
      getItem: 'markCategories/get',
      // getLookups: 'app/GET_LOOKUPS',
      submitItem: 'markCategories/put',
      getMarkCategories: 'markCategories/markCategories',
    }),
    init() {
      if (this.id) {
        this.getItem(this.id).then(_ => {
          console.log('item')
          this.form = this.item
        })
      } else {
        console.log('item2')

        this.form = {}
        // this.$refs.form.reset()
      }
    },
    submit() {
      // this.form_submit.term_id = this.form.term.id

      // console.log('form_submit', this.form_submit)

      const payload = { id: this.id, query: this.form }
      this.submitItem(payload).then(response => {
        // console.log('response', response.status)
        if (response.status === 'success' && this.id) {
          this.$swal({
            icon: 'success',
            title: 'Mark Category Edited!',
            text: 'Mark Category Successfully.',
            showConfirmButton: false,
            timer: 3000,
          })
          this.$router.push({ name: 'markCategories' })
        } else if (response.status === 'success' && this.id == null) {
          this.$swal({
            icon: 'success',
            title: 'Mark Category Added!',
            text: 'Mark Category Successfully.',
            showConfirmButton: false,
            timer: 3000,
          })
          this.$router.push({ name: 'markCategories' })
        }
      })
    },
    getSelectLabel() {
      return this.$i18n.locale === 'en' ? 'name' : 'name_local'
    },
     cancel() {
      if (this.inline) {
        return this.$emit('hide')
      }
      this.$router.push({ name: 'markCategories' })
    },
  },
}
</script>

<style scoped></style>
